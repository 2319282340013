/* eslint-disable sort-keys-fix/sort-keys-fix */
import { DefaultGenerics, Route } from "@tanstack/react-location"
import { lazy } from "react"
import { NotFoundError } from "src/helpers/errors"

// Misc
const App = lazy(() => import("./App"))

// Main
const Main = lazy(() => import("./App/Main"))
const Pool = lazy(() => import("./App/Main/Pool"))
const Dashboard = lazy(() => import("./App/Main/Pool/dashboard/Dashboard"))
const EventLog = lazy(() => import("./App/Main/Pool/eventlog/EventLog"))
const SourceViewV2Wrapper = lazy(
  () => import("./App/Main/Pool/sources/SourceView/SourceViewV2Wrapper")
)
const SourcesListV2Wrapper = lazy(
  () => import("./App/Main/Pool/sources/SourcesList/SourcesListV2Wrapper")
)
const SourceOverviewTab = lazy(() => import("./App/Main/Pool/sources/SourceView/OverviewTab"))
const SourceConfigurationTab = lazy(
  () => import("./App/Main/Pool/sources/SourceView/ConfigurationTab")
)
const LinksTab = lazy(() => import("./App/Main/Pool/sources/SourceView/LinksTab"))
const RedundancyTab = lazy(() => import("./App/Main/Pool/sources/SourceView/RedundancyTab"))
const SourceStreamEdit = lazy(
  () => import("./App/Main/Pool/sources/SourceView/ConfigurationTab/StreamEdit")
)
const SendersEdit = lazy(
  () => import("./App/Main/Pool/sources/SourceView/ConfigurationTab/SendersEdit")
)
const DestinationViewV2Wrapper = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/DestinationViewV2Wrapper")
)
const DestinationsListV2Wrapper = lazy(
  () => import("./App/Main/Pool/destinations/DestinationsList/DestinationsListV2Wrapper")
)
const DestinationOverviewTab = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/OverviewTab")
)
const DestinationSourceTab = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/SourceTab")
)
const DestinationConfigurationTab = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/ConfigurationTab")
)
const DestinationStreamEdit = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/ConfigurationTab/StreamEdit")
)
const TranscodingTab = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/TranscodingTab")
)
const ReceiversEdit = lazy(
  () => import("./App/Main/Pool/destinations/DestinationView/ConfigurationTab/ReceiversEdit")
)

const SchedulerEventsList = lazy(() => import("./App/Main/Pool/scheduler"))
const EditEvent = lazy(() => import("./App/Main/Pool/scheduler/SchedulerEventDetails"))

const Settings = lazy(() => import("./App/Main/Pool/settings/Settings"))
const AddPoolApiKey = lazy(() => import("./App/Main/Pool/settings/Settings/apiKeys/AddApiKey"))
const EditPoolApiKey = lazy(() => import("./App/Main/Pool/settings/Settings/apiKeys/EditApiKey"))
const Templates = lazy(() => import("./App/Main/Pool/templates"))

// Wizard
const Step1Source = lazy(() => import("./App/Wizard/steps/Step1Source"))
const Step2Redundancy = lazy(() => import("./App/Wizard/steps/Step2Redundancy"))
const Step3Destinations = lazy(() => import("./App/Wizard/steps/Step3Destinations"))
const Wizard = lazy(() => import("./App/Wizard"))

const ThrowNotFound = () => {
  throw new NotFoundError()
}

export const linkRoutes: Route<DefaultGenerics> = {
  path: "link",
  element: <App />,
  children: [
    {
      path: "pools",
      element: <Main />,
      children: [
        {
          path: ":poolUuid",
          element: <Pool />,
          children: [
            { path: "/", element: <Dashboard /> },
            {
              path: "destinations/:destinationUuid",
              element: <DestinationViewV2Wrapper />,
              children: [
                {
                  path: "overview",
                  element: <DestinationOverviewTab />
                },
                {
                  path: "transcoding",
                  element: <TranscodingTab />
                },
                {
                  path: "source",
                  element: <DestinationSourceTab />
                },
                {
                  path: "configuration",
                  element: <DestinationConfigurationTab />,
                  children: [
                    {
                      path: ":receiverUuid/:streamUuid",
                      element: <DestinationStreamEdit />
                    },
                    {
                      path: "receivers",
                      element: <ReceiversEdit />
                    }
                  ]
                }
              ]
            },
            { path: "destinations", element: <DestinationsListV2Wrapper /> },
            {
              path: "scheduler",
              children: [
                { path: "/", element: <SchedulerEventsList /> },
                { path: "edit_event/:scheduledEventUuid", element: <EditEvent /> }
              ]
            },
            { path: "settings/apikeys/new", element: <AddPoolApiKey /> },
            { path: "settings/apikeys/:apiKeyUuid", element: <EditPoolApiKey /> },
            { path: "settings", element: <Settings /> },
            { path: "eventlog", element: <EventLog /> },
            {
              path: "sources/:sourceUuid",
              element: <SourceViewV2Wrapper />,
              children: [
                {
                  path: "links",
                  element: <LinksTab />
                },
                {
                  path: "overview",
                  element: <SourceOverviewTab />
                },
                {
                  path: "redundancy",
                  element: <RedundancyTab />
                },
                {
                  path: "configuration",
                  element: <SourceConfigurationTab />,
                  children: [
                    {
                      path: ":senderUuid/:streamUuid",
                      element: <SourceStreamEdit />
                    },
                    {
                      path: "senders",
                      element: <SendersEdit />
                    }
                  ]
                }
              ]
            },
            { path: "sources", element: <SourcesListV2Wrapper /> },
            { path: "templates", element: <Templates /> }
          ]
        }
      ]
    },
    {
      path: "wizard/:poolUuid",
      element: <Wizard />,
      children: [
        { path: "source", element: <Step1Source /> },
        { path: "redundancy", element: <Step2Redundancy /> },
        { path: "destinations", element: <Step3Destinations /> },
        { element: <ThrowNotFound /> }
      ]
    }
  ]
}
