import clsx from "clsx"
import { FC, HTMLAttributes, PropsWithChildren } from "react"

interface Props extends HTMLAttributes<HTMLHeadingElement>, PropsWithChildren {}

const Title2: FC<Props> = ({ children, className, ...attrs }) => (
  <h2
    className={clsx("text-[1.75rem] font-bold leading-[1.125] tablet:text-size-2", className)}
    {...attrs}
  >
    {children}
  </h2>
)
export default Title2
