import { isValidElement, ReactNode } from "react"
import { Id, toast as toastify } from "react-toastify"
import GenericToast from "../base/toasts/GenericToast"
import { Toast, ToastType } from "../base/toasts/toastTypes"

const getToastOptions = (toastType: ToastType, toast?: Toast | string | ReactNode) => {
  toast = normalizeToast(toast)
  return {
    autoClose:
      (toast?.links && toast?.links?.length > 0) ||
      (toastType === "error" && toast.apiError) ||
      toast?.stay === true
        ? 200000
        : 4000,
    onOpen: () => {
      const root = document.getElementById("stretch")
      root?.removeAttribute("inert")
    }
  }
}

const normalizeToast = (toast?: Toast | string | ReactNode): Toast => {
  if (typeof toast === "string" || isValidElement(toast)) {
    // If notif is a string, set it as a message
    return { message: toast } as Toast
  } else {
    return toast as Toast
  }
}

const getToastComponent = (toastType: ToastType, toast?: Toast | string | ReactNode): any => {
  return <GenericToast toast={normalizeToast(toast)} type={toastType} />
}

function useToastManager() {
  return {
    error: (toast: Toast | string | ReactNode) =>
      toastify.error(getToastComponent("error", toast), getToastOptions("error", toast)),
    info: (toast?: Toast | string | ReactNode) => {
      toastify.info(getToastComponent("success", toast), getToastOptions("success", toast))
    },
    success: (toast?: Toast | string | ReactNode): Id =>
      toastify.success(getToastComponent("success", toast), getToastOptions("success", toast)),
    warn: (toast: Toast | string | ReactNode) =>
      toastify.warn(getToastComponent("warn", toast), getToastOptions("warn", toast))
  }
}

export { useToastManager }
