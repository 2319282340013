import {
  faCheck,
  faCircleInfo,
  faCircleExclamation,
  faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import { FC } from "react"
import Hypertext from "../buttons/Hypertext"
import ApiErrorToast from "./ApiErrorToast"
import { Toast, ToastType } from "./toastTypes"

type GenericToastProps = {
  type: ToastType
  toast?: Toast
}
const typeToIcon = {
  apiError: faTriangleExclamation,
  error: faTriangleExclamation,
  info: faCircleInfo,
  success: faCheck,
  warn: faCircleExclamation
} as const

const typeToIconColor = {
  apiError: "text-red-600",
  error: "text-red-600",
  info: "text-blue-600",
  success: "text-green-600",
  warn: "text-orange-600"
} as const

const GenericToast: FC<GenericToastProps> = ({ type, toast }) => {
  const message =
    !toast?.message && type === "success" ? "Your changes have been saved" : toast?.message

  const renderIcon = () => {
    return (
      <div className={clsx("w-2-auto flex", typeToIconColor[type])}>
        {<FontAwesomeIcon aria-hidden className="mr-xs" icon={typeToIcon[type]} size="lg" />}
      </div>
    )
  }

  return (
    <div className="flex">
      {renderIcon()}
      <div className="flex flex-col">
        {toast?.title && <p className="mb-2xs p-0 font-medium">{toast?.title}</p>}
        {toast?.apiError && <ApiErrorToast error={toast?.apiError} />}
        {message && <p className="font-normal p-0 text-sm">{message}</p>}
        {toast?.links && toast?.links.length > 0 && (
          <div className="mt-xs flex flex-row text-sm">
            {toast.links.map((link, index) => (
              <div key={"container" + index} className="mr-sm">
                <Hypertext key={"hp" + index} {...link} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default GenericToast
