import StoryPanel from "src/App/designSystem/App//tools/StoryPanel"
import LinkRedundancyFlow from "src/App/link/components/features/linkRedundancyFlow/LinkRedundancyFlow"

const LinkRedundancyFlowStory = () => {
  const sourceList = [
    {
      availabilityMode: "single_link",
      senders: [
        {
          status: {
            state: "active"
          },
          streams: [
            {
              link: "A",
              status: {
                selected: false,
                state: "disabled"
              }
            },
            {
              link: "A",
              status: {
                selected: true,
                state: "active"
              }
            }
          ]
        }
      ],
      status: {
        linksStates: {
          links: "active"
        },
        state: "active"
      }
    },
    {
      availabilityMode: "single_link_with_failover",
      senders: [
        {
          status: {
            state: "active"
          },
          streams: [
            {
              link: "A",
              status: {
                selected: true,
                state: "active"
              }
            }
          ]
        },
        {
          status: {
            state: "standby"
          },
          streams: [
            {
              link: "A",
              status: {
                selected: false,
                state: "down"
              }
            }
          ]
        }
      ],
      status: {
        linksStates: {
          links: "active"
        },
        state: "active"
      }
    },
    {
      availabilityMode: "dual_link",
      senders: [
        {
          status: {
            state: "active"
          },
          streams: [
            {
              link: "A",
              status: {
                selected: true,
                state: "active"
              }
            }
          ]
        },
        {
          status: {
            state: "active"
          },
          streams: [
            {
              link: "B",
              status: {
                selected: true,
                state: "active"
              }
            }
          ]
        }
      ],
      status: {
        linksStates: {
          links: "active"
        },
        state: "active"
      }
    },
    {
      availabilityMode: "dual_link_with_failover",
      senders: [
        {
          status: {
            state: "down"
          },
          streams: [
            {
              link: "A",
              status: {
                selected: false,
                state: "down"
              }
            },
            {
              link: "B",
              status: {
                selected: false,
                state: "disabled"
              }
            }
          ]
        },
        {
          status: {
            state: "active"
          },
          streams: [
            {
              link: "A",
              status: {
                selected: true,
                state: "active"
              }
            },
            {
              link: "B",
              status: {
                selected: true,
                state: "down"
              }
            }
          ]
        }
      ],
      status: {
        linksStates: {
          links: "active"
        },
        state: "active"
      }
    }
  ] as api.link.SourceResponse[]

  const destinationList = [
    {
      availabilityMode: "single_link",
      receivers: [
        {
          status: {
            state: "active"
          },
          streams: [
            {
              status: {
                state: "active"
              }
            }
          ]
        }
      ],
      status: {
        linksStates: {
          linkA: "active",
          linkB: "disabled",
          links: "active"
        },
        state: "active"
      }
    },
    {
      availabilityMode: "dual_link",
      receivers: [
        {
          status: {
            state: "active"
          },
          streams: [
            {
              status: {
                state: "active"
              }
            }
          ]
        },
        {
          status: {
            state: "active"
          },
          streams: [
            {
              status: {
                state: "active"
              }
            }
          ]
        }
      ],
      status: {
        linksStates: {
          linkA: "active",
          linkB: "active",
          links: "active"
        },
        state: "active"
      }
    },
    {
      availabilityMode: "dual_link",
      receivers: [
        {
          status: {
            state: "active"
          },
          streams: [
            {
              status: {
                state: "active"
              }
            }
          ]
        },
        {
          status: {
            state: "down"
          },
          streams: [
            {
              status: {
                state: "down"
              }
            }
          ]
        }
      ],
      status: {
        linksStates: {
          linkA: "active",
          linkB: "down",
          links: "warning"
        },
        state: "active"
      }
    }
  ] as api.link.DestinationResponse[]

  return (
    <>
      <StoryPanel title="With destination">
        <LinkRedundancyFlow destination={destinationList[0]!} source={sourceList[0]!} />
        <LinkRedundancyFlow destination={destinationList[0]!} source={sourceList[1]!} />
        <LinkRedundancyFlow destination={destinationList[1]!} source={sourceList[2]!} />
        <LinkRedundancyFlow destination={destinationList[2]!} source={sourceList[3]!} />
      </StoryPanel>
      <StoryPanel title="Without destination">
        <LinkRedundancyFlow destination={destinationList[0]!} onlySource source={sourceList[0]!} />
        <LinkRedundancyFlow destination={destinationList[0]!} onlySource source={sourceList[1]!} />
        <LinkRedundancyFlow destination={destinationList[1]!} onlySource source={sourceList[2]!} />
        <LinkRedundancyFlow destination={destinationList[2]!} onlySource source={sourceList[3]!} />
      </StoryPanel>
    </>
  )
}

export default LinkRedundancyFlowStory
